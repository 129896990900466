@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;600&display=swap");

body {
  margin: 0;
  line-height: normal;
  font-family: var(--font-kanit), sans-serif;
}

.service1 {
  font-family: 'IBM Plex Sans Thai', sans-serif; /* ตั้งค่าสำหรับ class service1 */
}

:root {
  /* fonts */
  --font-kanit: 'Kanit';

  /* font sizes */
  --font-size-10xl: 29px;
  --font-size-mini: 15px;

  /* Colors */
  --color-black: #000;
  --gray: #185042;

  /* Paddings */
  --padding-17xl: 36px;
  --padding-2xl: 21px;
  --padding-xl: 20px;
  --padding-8xs: 5px;
}
