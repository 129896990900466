.logo-icon {
  width: 413px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.service1title {
  margin: 0;
  width: 304px;
  height: 46.6px;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
}

.p3,
.p4 {
  margin: 0;
}

.locksmith-pattaya1 {
  font-weight: 600;
  font-family: Inter;
}

.span {
  font-family: Inter;
}

.blank-line,
.locksmith-pattaya,
.p5 {
  margin: 0;
}

.span1 {
  font-family: Inter;
}

.locksmith-pattaya3 {
  font-weight: 600;
  font-family: Inter;
}

.blank-line1,
.locksmith-pattaya2 {
  margin: 0;
}

.li6,
.li7,
.li8 {
  margin-bottom: 0;
}

.ul2 {
  margin: 0;
  font-size: inherit;
  padding-left: 27px;
}

.description {
  width: 623px;
  height: 341.4px;
  position: relative;
  font-size: 16px;
  color: #000;
  display: inline-block;
  flex-shrink: 0;
  max-height: 341.40000000000003px;
  word-break: break-word;
}

.service-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10.7px;
  flex-shrink: 0;
}

.service-details-wrapper,
.service1 {
  max-width: 100%;
  display: flex;
  box-sizing: border-box;
}

.service-details-wrapper {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 4.8px;
  min-width: 623px;
}

.service1 {
  width: 1360px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 68.5px 20px 148px;
  gap: 20px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 32px;
  color: #185042;
  font-family: Inter;
  height: 100vh; /* ใช้ความสูง 100vh */
  box-sizing: border-box; /* รวม padding ในความสูงทั้งหมด */
}

/* MacBook Pro 1440 * 900 */
@media screen and (max-width: 1440px) and (min-height: 900px) {
  .service1title {
    font-size: 24px;
  }
  .description {
    font-size: 18px;
  }
}

/* General Laptop 1366 * 768 */
@media screen and (max-width: 1366px) and (min-height: 768px) {
  .service1title {
    font-size: 22px;
  }
  .description {
    font-size: 16px;
  }
}

/* iPad 768 * 1024 */
@media screen and (max-width: 768px) and (min-height: 1024px) {
  .service1title {
    font-size: 20px;
  }
  .description {
    font-size: 15px;
  }
  .service1 {
    padding: 15px;
    font-size: 16px;
  }
}

/* iPhone 12 Pro 790 * 844 */
@media screen and (max-width: 790px) and (min-height: 844px) {
  .service1title {
    font-size: 18px;
  }
  .description {
    font-size: 14px;
  }
  .service1 {
    padding: 10px;
    font-size: 14px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    display: flex;
    flex-direction: column;
    align-items: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    justify-content: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
  }
}

/* iPhone X 375 * 812 */
@media screen and (max-width: 375px) and (min-height: 812px) {
  .service1title {
    font-size: 16px;
    width: 100%;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
  }
  .description {
    font-size: 12px;
    width: 100%;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
  }
  .service1 {
    padding: 8px;
    font-size: 12px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    display: flex;
    flex-direction: column;
    align-items: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    justify-content: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    height: 100vh; /* ใช้ความสูง 100vh */
  }
}
