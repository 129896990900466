@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700&display=swap');

.title {
  position: absolute;
  top: 57px;
  left: 191px;
  font-size: 48px;
  color: var(--gray);
}

.subtitle {
  position: absolute;
  top: 124px;
  left: 230px;
  font-size: 24px;
}

.container {
  position: absolute;
  top: 167px;
  left: calc(50% - 530px);
  text-align: center;
  display: inline-block;
  width: 413px;
  height: 120px;
}

.p {
  margin: 0;
}

.li {
  margin-bottom: 0;
}

.ul {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: 20px;
}

.div,
.servicelist {
  position: absolute;
  top: 287px;
  left: 257px;
  display: inline-block;
  width: 236px;
  height: 133px;
}

.div {
  top: 413px;
  left: 42px;
  text-align: center;
  width: 629px;
  height: 48px;
}

.heroimage-icon {
  position: absolute;
  top: -39px;
  left: 694px;
  width: 720px;
  height: 684px;
  object-fit: cover;
}

.telbutton {
  position: absolute;
  top: 461px;
  left: 210px;
  font-size: 36px;
  font-weight: 600;
  color: #fe0303;
  background-color: transparent;
  text-align: center;
  border: none;
}

.linebutton-icon,
.phonebutton-icon {
  position: absolute;
  top: 5px;
  left: 259px;
  width: 19.8px;
  height: 30px;
}

.linebutton-icon {
  left: 83px;
  width: 30px;
}

.whatsappbutton-icon {
  position: absolute;
  top: 0;
  left: 166px;
  width: 40px;
  height: 40px;
}

.linkcontact,
.messengerbutton-icon {
  position: absolute;
  top: 5px;
  left: 0;
  width: 30px;
  height: 30px;
}

.linkcontact {
  top: 544px;
  left: 219px;
  width: 278.8px;
  height: 40px;
}

.home {
  width: 1360px;
  background-color: #fff;
  max-width: 100%;
  height: 100vh; /* ใช้ความสูง 100vh */
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-black);
  font-family: var(--font-kanit);
}

/* MacBook Pro 1440 * 900 */
@media screen and (max-width: 1440px) and (min-height: 900px) {
  .title {
    font-size: 40px;
  }
  .subtitle {
    font-size: 20px;
  }
  .container {
    width: 360px;
  }
}

/* General Laptop 1366 * 768 */
@media screen and (max-width: 1366px) and (min-height: 768px) {
  .title {
    font-size: 36px;
  }
  .subtitle {
    font-size: 18px;
  }
  .container {
    width: 340px;
  }
}

/* iPad 768 * 1024 */
@media screen and (max-width: 768px) and (min-height: 1024px) {

  .home {
    padding: 10px;
    font-size: 14px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    display: flex;
    flex-direction: column;
    align-items: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    justify-content: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
  }
  .title {
    position: absolute;
    top: 57px;
    left: 191px;
    font-size: 48px;
    color: var(--gray);
  }
  
  .subtitle {
    font-size: 18px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    left: 100px;
    top: 100px;
  }
  .container {
    width: 260px;
    left: 65px;
    top: 150px;
    font-size: 14px;
  }

    .div,
    .servicelist {
      position: absolute;
      top: 320px;
      left: 80px;
      display: inline-block;
      width: 236px;
      height: 200px;
      font-size: 14px;
    }
    
    .div {
      top: 450px;
      left: 50px;
      text-align: center;
      width: 280px;
      height: 300px;
    }
    
  
  .telbutton {
    top: 550px;
    left: 100px;
    font-size: 20px;
    font-weight: 600;
  }
  .linkcontact {
    margin-top: 150px;
    left: 50px;
  }
}

/* iPhone 12 Pro 790 * 844 */
@media screen and (max-width: 790px) and (min-height: 844px) {
  .home {
    padding: 10px;
    font-size: 14px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    display: flex;
    flex-direction: column;
    align-items: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    justify-content: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
  }
  .title {
    font-size: 28px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    left: 100px;
  }
  .subtitle {
    font-size: 18px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    left: 100px;
    top: 100px;
  }
  .container {
    width: 260px;
    left: 65px;
    top: 150px;
    font-size: 14px;
  }

    .div,
    .servicelist {
      position: absolute;
      top: 320px;
      left: 80px;
      display: inline-block;
      width: 236px;
      height: 200px;
      font-size: 14px;
    }
    
    .div {
      top: 450px;
      left: 50px;
      text-align: center;
      width: 280px;
      height: 300px;
    }
    
  
  .telbutton {
    top: 550px;
    left: 100px;
    font-size: 20px;
    font-weight: 600;
  }
  .linkcontact {
    margin-top: 150px;
    left: 50px;
  }
}

/* iPhone X 375 * 812 */
@media screen and (max-width: 375px) and (min-height: 812px) {
  .home {
    padding: 8px;
    font-size: 12px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    display: flex;
    flex-direction: column;
    align-items: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    justify-content: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    height: 100vh; /* ใช้ความสูง 100vh */
  }
  .title {
    font-size: 28px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    left: 100px;
  }
  .subtitle {
    font-size: 18px;
    text-align: center; /* จัดเนื้อหาให้อยู่ตรงกลาง */
    left: 100px;
    top: 100px;
  }
  .container {
    width: 260px;
    left: 65px;
    top: 150px;
    font-size: 14px;
  }

    .div,
    .servicelist {
      position: absolute;
      top: 320px;
      left: 80px;
      display: inline-block;
      width: 236px;
      height: 200px;
      font-size: 14px;
    }
    
    .div {
      top: 450px;
      left: 50px;
      text-align: center;
      width: 280px;
      height: 300px;
    }
    
  
  .telbutton {
    top: 550px;
    left: 100px;
    font-size: 20px;
    font-weight: 600;
  }
  .linkcontact {
    margin-top: 150px;
    left: 50px;
  }
}

