:root {
  --font-kanit: 'Kanit', sans-serif;
  --font-size-10xl: 29px;
  --font-size-mini: 15px;
  --color-black: #000;
  --gray: #185042;
  --padding-17xl: 36px;
  --padding-2xl: 21px;
  --padding-xl: 20px;
  --padding-8xs: 5px;
}

.home {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 60.5px 42px var(--padding-17xl);
  box-sizing: border-box;
  gap: 23px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-black);
  font-family: var(--font-kanit);
}

.frame-parent {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.frame-wrapper,
.title-parent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.title-parent {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.frame-wrapper {
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: 48px;
  color: var(--gray);
}

.title,
.subtitle {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}

.subtitle {
  font-weight: 400;
}

.subtitle-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-17xl);
  margin-top: -10.3px;
  font-size: 24px;
  color: var(--color-black);
}

.container {
  width: 100%;
  max-width: 413px;
  position: relative;
  display: inline-block;
  word-break: break-word;
}

.p {
  margin: 0;
}

.ul {
  margin: 0;
  padding-left: var(--padding-xl);
}

.servicelist {
  width: 100%;
  max-width: 236px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}

.servicelist-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 70px;
  text-align: left;
}

.container-parent {
  width: 100%;
  max-width: 413px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}

.frame-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 4px;
  box-sizing: border-box;
}

.p1 {
  margin: 0;
}

.div,
.telbutton {
  position: relative;
}

.div {
  width: 100%;
  max-width: 629px;
  display: inline-block;
  word-break: break-word;
}

.telbutton {
  font-weight: 600;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
}

.telbutton-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  font-size: 36px;
  color: #fe0303;
}

.parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 22px;
}

.messengerbutton-icon,
.linebutton-icon,
.whatsappbutton-icon,
.phonebutton-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.whatsappbutton-icon {
  width: 40px;
  height: 40px;
}

.button-trio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}

.button-trio-parent {
  width: 100%;
  max-width: 278.8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.heroimage-icon {
  width: 100%;
  max-width: 720px;
  height: auto;
  margin-bottom: -61px;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .frame-parent {
    max-width: 100%;
  }
  .heroimage-icon {
    display: none;
  }
  .home {
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
  }
}

@media screen and (max-width: 1050px) {
  .title {
    font-size: 38px;
  }
  .telbutton {
    font-size: var(--font-size-10xl);
  }
  .frame-parent {
    display: none;
  }
  .heroimage-icon {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: var(--font-size-10xl);
  }
  .subtitle {
    font-size: 19px;
  }
  .servicelist-wrapper {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
  }
  .telbutton {
    font-size: 22px;
  }
}
